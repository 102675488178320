.container-carrosselReceitas{
    width: 100%;
    min-height: auto;


    /* background-color: red; */
    display: flex;
    flex-direction: column;
    align-items: center;

    margin: 30px 0 150px 0;

}

.CarrosselReceitas-title{
    font-family: "Post Bus";
    margin: 20px 0;

    font-size: 2.3rem;




}


.Container-GeralCarrosselReceitas{
    width: 100%;
    min-height: auto; 


    align-items: center;
    justify-content: center;

    display: flex;

}



.Static-carrosselReceitas{
    width: 80%;

    /* background-color: blue; */

    overflow: hidden;
}


.Slide-carrosselReceitas{
    width: 100%;
    height: 100%;

    /* background-color: aqua; */

    display: flex;

    transform: translateX(0);

    transition: .3s ease-in-out;
}


.Item-slidecarrosselReceitas{
    min-width: 25%;
    max-width: 25%;
    height: 100%;
    /* background-color: black; */

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;


}


.Image-slideCarrosselReceitas{
    width: 80%;
    height: 250px;
    
    border-radius: 10px;

    background-size: cover;
    background-position: center;
    cursor: pointer;

}

.Title-CarrosselReceitas{
    width: 80%;
    padding: 10px 0;

    height: 60px;

    white-space: wrap;

    text-align: center;
    font-size: 1.3rem;
    font-family: "Mustica";

}

.bot button{
    padding: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    
    border-radius: 100%;
    border: 2px solid var(--Branco);
    outline: 2px solid var(--Amarelo);
    background-color: var(--Amarelo);
    color: var(--Branco);

    font-size: 2rem;

    transition: .3s ease-in-out;

    cursor: pointer;
}
.bot button:hover{
    border: 2px solid var(--Amarelo);
    outline: 1px solid var(--Amarelo);
    background-color: var(--Branco);
    color: var(--Amarelo);

}


@media screen  and (max-width:1100px){
    
    .Item-slidecarrosselReceitas{
        min-width: 33%;
        max-width: 33%;

    }


}

@media screen  and (max-width:850px){
    
    .Item-slidecarrosselReceitas{
        min-width: 50%;
        max-width: 50%;

    }


}


@media screen  and (max-width:570px){
    
    .Item-slidecarrosselReceitas{
        min-width: 100%;
        max-width: 100%;
        
    }


}