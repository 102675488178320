.BannerContato{
    position: relative;
    width: 100%;
    height: auto;

    box-shadow: 0 0 10px black;


    background-image: url('../img/Image3.JPG');
    background-size: cover;
    background-position: center;
    
}


.ContentBanner{
    width: 100%;
    height: 100%;

    background-color: rgba(0, 0, 0, 0.626);
}

.ContainerContatoInfor{
    width: 100%;
    height: auto;

    margin-top: 40px;
    /* min-height: 400px; */
    /* background-color: red; */

    display: flex;
    flex-wrap: wrap;
}


.MapResponsive{
    position: relative;
    flex: 1;

    min-height: 400px;
    min-width: 300px;

    /* background-color: green; */

    display: flex;
    justify-content: center;
    align-items: center;


}

.MapResponsive iframe{
    position: relative;
    width: 90%;
    height: 100%;

    border: 0;

    
}

.InforContent{
    flex: 1;

    min-width: 300px;

    display: flex;
    flex-direction: column;
}

.InformationContatEspecifc{
    position: relative;

    display: flex;
    align-items: center;

    margin: 10px 20px;

    font-family: "Mustica";


}

.InformationContatEspecifc span{
    font-size: 1.5rem;
    margin: 2px;


    display: flex;
    justify-content: center;
    align-items: center;

    color: var(--Amarelo);
}


hr{
    width: 80%;
    align-self: center;

    margin: 20px 0;
}