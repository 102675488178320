.Container-Item-Receita{
    position: relative;
    flex: 1;

    min-width: 280px;
    max-width: 320px;

    height: 400px;

    margin: 10px 30px;


    display: flex;
    flex-direction: column;

    border-radius: 5px;
    box-shadow: 0 0 5px black;

    cursor: pointer;

    transition: .3s ease-in-out;
    -o-transition: .3s ease-in-out;
    -moz-transition: .3s ease-in-out;
    -webkit-transition: .3s ease-in-out;
}



.Container-Item-Receita:hover{
    box-shadow: 0 0 10px black;
    transform: scale(1.05);
}



/* 
.Container-Item-Receita:hover .text-receita{
    background-color: var(--Vermelho);
    color: var(--Branco);
} */



.image-receita{
    position: relative;
    width: 100%;
    height: 70%;

    border-top-right-radius: 5px;
    border-top-left-radius: 5px;

    background-size: cover;
    background-position: center;
}


.text-receita{
    min-width: 100%;
    max-width: 100%;
    height: 30%;

    flex-wrap: wrap;

    


    

    display: flex;
    justify-content: center;
    align-items: center;

    font-family: "Mustica";
    font-size: 1.8rem;

    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;

    transition: .3s ease-in-out;
    -o-transition: .3s ease-in-out;
    -moz-transition: .3s ease-in-out;
    -webkit-transition: .3s ease-in-out;
}

.text-receita p{
    text-align: center;
    max-width: 90%;
}