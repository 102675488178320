.Container-BannerReceitas{
    width: 100%;
    height: auto;

    background-image: url('../img/Image1.JPG');
    background-size: cover;
    background-position: center;

    box-shadow: 0 3px 10px black;



}

.BackContrast{
    width: 100%;
    height: 100%;

    background-color: rgba(0, 0, 0, 0.626);

}



.Form-BannerReceitas-buscar{
    position: relative;
    width: 100%;
    height: auto;

    display: flex;
    justify-content: center;
    align-items: center;

    margin-bottom: 50px;


}

.Form-BannerReceitas-buscar form{
    position: relative;
    width: 600px;
    height: 70px;
    border-radius: 40px;

    display: flex;
    border: 2px solid var(--Amarelo);

    
}

.Form-BannerReceitas-buscar form input{
    flex: 1;

    border-bottom-left-radius: 40px;
    border-top-left-radius: 40px;
    border: 0;

    padding-left: 15px;
    font-size: 1.3rem;
    font-family: "Mustica";
    background-color: transparent;

    color: var(--Branco);

}




.Form-BannerReceitas-buscar form input:focus{
    outline: 0;
}






.Form-BannerReceitas-buscar form button{
    flex: 1;

    max-width: 100px;

    border-bottom-right-radius: 40px;
    border-top-right-radius: 40px;
    border: 0;


    font-size: 2.8rem;
    display: flex;
    justify-content: center;
    align-items: center;

    cursor: pointer;

    background-color: transparent;

    color: var(--Amarelo);

}


.Form-BannerReceitas-resultado{
    position: absolute;
    background-color: whitesmoke;

    width: 100%;
    max-height: 300px;
    height: auto;
    border-radius: 5px;

    overflow-y: scroll;

    z-index: 1;


    top: 70px;

    display: flex;
    flex-direction: column;


}




.Form-BannerReceitas-resultado .Item-BannerReceitas-resultado{
    position: relative;
    width: 100%;
    height: 120px;
    margin: 3px 0;


    border-bottom: 1px solid grey;

    display: flex;
    align-items: center;

    cursor: pointer;
}

.Item-BannerReceitas-resultado-foto{

    height: 110px;
    width: 110px;

    margin-left: 10px;
    

    background-position: center;
    background-size: cover;




}


.Item-BannerReceitas-resultado-nome{

    padding-left: 10px;

    font-size: 1.3rem;
    font-family: "Mustica";





}


.Error-BannerReceitas-resultado{
    width: 100%;
    height: 100px;

    display: flex;
    justify-content: center;
    align-items: center;

    font-size: 2rem;
    font-family: "Post Bus";
}


@media screen  and (max-width:600px){
    .Form-BannerReceitas-buscar form{
        width: 90%;
    }
    
}