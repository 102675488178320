@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;500;600;700;800&display=swap');


:root{
  --Vermelho: rgba(151,1,3);
  --Amarelo: rgba(255,204,41);
  --Branco: rgba(255,255,255,1);
  --Preto: rgba(0,0,0,1);
}


*{
  margin: 0;
  padding: 0;

  line-height: 1;

  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;

}





#root,html,body{
  width: 100vw;
  height: 100vh;

  overflow-x: hidden;
}



@font-face{
  font-family: "Mustica";
  src: local(mustica), url('./components/fonts/mustica_pro/MusticaPro-SemiBold.otf');
}


@font-face{
  font-family: "Post Bus";
  src: local(postBus), url('./components/fonts/postbus/PostBusPro.ttf');
}

@font-face{
  font-family: "Next";
  src: local(NextArt), url('./components/fonts/next_art/NEXT\ ART_Regular.otf');
}

