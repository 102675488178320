.Banner-container{
    position: relative;
    width: 100%;
    height: auto;

    background-image: url('../img/ImageHamburguer.jpg');
    background-size: cover;
    background-position: center;

    box-shadow: 0 0 10px black;

}


.Content-Banner{
    position: relative;
    width: 100%;
    min-height: 100%;
    height: auto;

    background-color: rgba(0, 0, 0, 0.626);

    display: flex;
    flex-direction: column;

}


.Header{
    position: relative;
    width: 100%;
    height: 80px;

    display: flex;

    flex-direction: column;
}





.Logo-header{
    flex: 1;


    display: flex;
    align-items: center;
    justify-content: center;
}


.Logo-header p{
    font-size: 2.3rem;
    padding: 10px;

    font-family: "Mustica";

    color: var(--Branco)

}

.Menu-header{
    flex: 1;


    display: flex;
    justify-content: center;

}


.Menu{
    height: 100%;
    list-style-type: none;
    display: flex;
    justify-content: center;
    align-items: center;

    flex-wrap: wrap;

    /* margin-right: 30px; */
}

.Menu li{
    position: relative;
    margin: 10px;
    font-family: "Next";
    font-size: 1.2rem;

    color: var(--Branco);
    cursor: pointer;



}




/*TÍTULO D0 BANNER*/


.Title-Banner{
    position: relative;
    margin-top: 100px;
    width: 100%;
    height: 250px;


    display: flex;
    justify-content: center;
    align-items: center;

    animation: Aparecer 1.5s ease-in forwards;
    -o-animation: Aparecer 1.5s ease-in-out;
    -moz-animation: Aparecer 1.5s ease-in-out;
    -webkit-animation: Aparecer 1.5s ease-in-out;
}

.Title-Banner p {
    width: 900px;
    text-align: center;
    font-size: 3rem;
    font-family: "Post Bus";

    color: var(--Branco);
}


















































/*Efeitos*/



.amarelo{
    color: var(--Amarelo) !important;
}

.vermelho{
    color: var(--Vermelho) !important;
}

.branco{
    color: var(--Branco) !important;
}




.effectedY::before{
    content: '';
    position: absolute;

    width: 0;
    height: 1px;
    background-color: yellow;
    bottom: 0;

    transition: .3s ease-in-out;
    -o-transition: .3s ease-in-out;
    -moz-transition: .3s ease-in-out;
    -webkit-transition: .3s ease-in-out;
}


.effectedY:hover::before{
    width: 100%;
}



.effected::before{
    content: '';
    position: absolute;

    width: 0;
    height: 1px;
    background-color: var(--Branco);
    bottom: 0;

    transition: .3s ease-in-out;
    -o-transition: .3s ease-in-out;
    -moz-transition: .3s ease-in-out;
    -webkit-transition: .3s ease-in-out;
}


.effected:hover::before{
    width: 100%;
}


@keyframes Aparecer {
    0%{
        opacity: 0;
    }100%{
        opacity: 1;
    }
}







a{
    text-decoration: none;
}






@media screen and (min-width:400px) {
    .Menu li{
        font-size: 1.4rem;
    }
}


@media screen and (min-width:670px) {
    .Header{
        flex-direction: row;
    }

    .Logo-header{
        justify-content: flex-start;
    }

    .Menu-header{
        justify-content: flex-end;
    }

    .Menu{
        margin-right: 30px;
    }



    .Title-Banner p{
        font-size: 4rem;
    }

}


@media screen and (min-width:724px){

    .Banner-container{
        min-height: 100%;
    }


}