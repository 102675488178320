.Box-receita{
    position: relative;
    width: 100%;
    height: auto;

    display: flex;
    flex-direction: column;
    align-items: center;



    margin-top: 70px;
}

.Receitas-Title{
    margin: 20px;
    font-size: 3rem;

    font-family: "Post Bus";
}



.Container-Receitas{
    position: relative;
    width: 100%;
    height: auto;

    display: flex;
    justify-content: center;
    flex-wrap: wrap;


}

a{
    text-decoration: none;
    color: var(--Preto);
}