.Container-ReceitaUnica{
    width: 100%;
    height: 100%;


    display: flex;
    flex-direction: column;
    
    

}


.BannerReceitaUnica{
    width: 100%;
    height: auto;

    box-shadow: 0 0 10px black;


    background-image: url('../img/Image3.JPG');
    background-size: cover;
    background-position: center;
}



.Content-BannerReceitaUnica{
    width: 100%;
    height: 100%;

    background-color: rgba(0, 0, 0, 0.626);

}




.Content-Informacoes{
    width: 100%;
    height: auto;


    display: flex;
}

.ImageContainer-Informacoes{
    flex: 1;

    min-height: 450px;


    display: flex;
    justify-content: center;
    align-items: center;
}

.ImageInfo{

    width: 70%;
    height: 90%;

    background-size: cover;
    background-position: center;

    border-radius: 10px;
}


.contentContainer-Informacoes{
    flex: 1;

    margin-top: 30px;
    height: auto;
    display: flex;
    flex-direction: column;
}




.TimeItens-infor{
    width: 100%;
    height: 100px;

    position: relative;
    display: flex;

    justify-content: center;

}


.ItemTime{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    font-size: 1.1rem;
    font-family: "Mustica";

    padding: 10px;
}

.ItemTime span{
    font-size: 1.8rem;
    color: var(--Amarelo);
}   




.text-infor{
    position: relative;
    width: 100%;
    height: 100%;

    padding: 20px 10px;

    font-family: "Mustica";
    font-size: 1.2rem;
}







.Content-ReceitaUnica{
    width: 100%;
    height: auto;

    display: flex;
    flex-wrap: wrap;


    padding: 10px 30px;


}

.Content-Ingredientes{
    width: 50%;
    height: auto;

    /* background-color: green; */

    display: flex;
    flex-direction: column;
}



.IngredientesTitle{

    margin: 20px 10px;

    font-size: 2rem;
    font-family: "Post Bus";

    color: var(--Amarelo);
    
}

.IngredientesTitle span{
    font-size: 2.5rem;
}


.IngretientesReceita{
    width: 100%;
    height: auto;

    /* background-color: yellow; */
    
}

.IngretientesReceita li{
    margin: 20px 40px;

    font-family: "Mustica";
    font-size: 1.3rem;


    max-width: 90%;

    overflow-wrap: break-word;


}
















.Content-ModoPreparo{
    width: 50%;
    height: auto;

    /* background-color: red; */

    display: flex;
    flex-direction: column;
}





@media screen and (max-width:700px) {
    .Content-Informacoes{
        flex-direction: column;
    }


    .Content-ReceitaUnica{
        flex-direction: column;

        padding: 0;
        
    }


    .Content-ModoPreparo{
        width: 100%;
        margin-top: 70px;

    }

    .Content-Ingredientes{
        width: 100%;

    }


}