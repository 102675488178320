.Selos-Banner{
    width: 100%;
    min-height: 200px;

    display: flex;
    justify-content: center;
    flex-wrap: wrap;
}

.Item-selos{
    width: 200px;
    height: 200px;

    margin: 10px 20px;

    border: 1px solid var(--Amarelo);

    transition: 1s ease-in-out;
    -o-transition: 1s ease-in-out;
    -moz-transition: 1s ease-in-out;
    -webkit-transition: 1s ease-in-out;

    display: flex;
    flex-direction: column;
}


.icon{
    width: 100%;
    height: 40%;
    display: flex;
    justify-content: center;
    align-items: center;

    font-size: 2rem; /*Modificar caso selo*/
    color: var(--Amarelo);

    font-family: "Mustica";


}


.Content-Selos{
    flex: 1;

    font-size: 1.1rem;
    font-family: "Mustica";
    text-align: center;
    color: var(--Branco);


}

.Item-selos:hover{
    border: 1px solid var(--Vermelho);

}