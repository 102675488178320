.container{
    display: flex;
    justify-content: flex-start;
}


.center{
    justify-content: center;
    align-items: center;
}

.collumn{
    flex-direction: column;
}


.all{
    width: 100%;
    height: 100%;
}


