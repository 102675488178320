.Rede-Container {
  width: 100%;
  height: auto;
  /* min-height: 120px; */

  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  list-style-type: none;
}

.Rede-Container li {
  width: 50px;
  height: 50px;

  background-color: var(--Amarelo);
  display: flex;
  justify-content: center;
  align-items: center;

  font-size: 1.8rem;
  color: var(--Branco);

  border: 2px solid var(--Amarelo);
  border-radius: 100%;

  transition: 0.3s ease-in-out;
  -o-transition: 0.3s ease-in-out;
  -moz-transition: 0.3s ease-in-out;
  -webkit-transition: 0.3s ease-in-out;
  cursor: pointer;

  margin: 15px;
}

.Rede-Container li:hover {
  background-color: var(--Branco);
  color: var(--Amarelo);
}



.Rede-Container2{
  width: 100%;
  height: auto;
  /* min-height: 120px; */

  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  list-style-type: none;
}

.Rede-Container2 li {
  width: 50px;
  height: 50px;

  background-color: #1e1e1e;
  display: flex;
  justify-content: center;
  align-items: center;

  font-size: 1.8rem;
  color: var(--Branco);

  border: 1px solid var(--Branco);
  border-radius: 100%;

  transition: 0.3s ease-in-out;
  -o-transition: 0.3s ease-in-out;
  -moz-transition: 0.3s ease-in-out;
  -webkit-transition: 0.3s ease-in-out;
  cursor: pointer;

  margin: 15px;
}

.Rede-Container2 li:hover {
  background-color: var(--Branco);
  color: var(--Preto);

}