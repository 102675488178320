.Sobre-Container{
    position: relative;
    width: 100%;
    

    display: flex;
    
    /* background-color: red; */
    margin: 10px 0;
}


.invert{
    flex-direction: row-reverse;
}


.textStart{
    text-align: start !important;

}



.textEnd{
    text-align: end !important;

}


.Sobre-content{
    position: relative;
    flex: 1;
    
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.Sobre-title{
    font-family: "Post Bus" ;
    font-size: 2rem;
    margin: 10px;
    text-align: end;
}


.Sobre-text{
    font-family: "Mustica";
    font-size: 1.3rem;
    margin: 10px;
    text-align: end;

}

.Sobre-text p{
    margin-bottom: 15px;
    padding: 0 9px;
}







.Sobre-image{
    min-width: 200px;
    min-height: 200px;
    flex: 1;
    
    display: flex;
    justify-content: center;
    align-items: center;
    

}



.Image{
    width: 80%;
    max-width: 600px;

    background-size: cover;
    background-position: center;

    border-radius: 10px;
    transition: .3s ease-in-out;
    -o-transition: .3s ease-in-out;
    -moz-transition: .3s ease-in-out;
    -webkit-transition: .3s ease-in-out;


    box-shadow: 0 0 7px rgba(0, 0, 0, 0.333);

}



@media screen and (max-width:500px) {
    .Image{
        width: 95%;
    }
}



@media screen and (max-width:900px) {
    .Sobre-Container{
        flex-direction: column;

    }

    .Sobre-title{
        text-align: center;
    }

    .Sobre-text{
        text-align: center;
    }

    .Sobre-image{
        min-height: 350px;
    }

    .Sobre-text{
        font-size: 1.2rem;
    }


}

@media screen and (min-width:1200px){
    .Sobre-Container{
        min-height: 400px;

    }
}