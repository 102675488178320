.Footer{
    width: 100%;
    height: auto;

    margin-top: 20px;

    background-color: #1e1e1e;

    

    border-top: 1px solid grey;
    display: flex;
    flex-direction: column;
}

.Col{
    width: 100%;
    height: auto;

    display: flex;
}



.LogoFooter{
    flex: 1;
    height: 200px;

    display: flex;
    align-items: center;

    font-size: 2.3rem;
    padding: 10px;

    font-family: "Mustica";

    color: var(--Branco)
}

.LogoFooter p {
    margin-left: 20px;
}


.Page{
    flex: 1;


    display: flex;
    flex-direction: column;
    justify-content: center;
    
}

.Page p{

    margin: 2px 10px;
    

}

.Page p a{
    font-family: "Next";
    font-size: 1.1rem;
    color: rgba(255, 255, 255, 0.686);
}

#footerHR{
    margin: 0 0 10px 0;

}


.text{
    display: flex;
    justify-content: center;

    padding: 10px 0;
    font-family: "Mustica";

    color: var(--Branco);
    text-align: center;
}



@media screen and (max-width:500px){

    .Col{
        flex-direction: column;
    }

    .Page{
        margin: 20px;
    }
    
}